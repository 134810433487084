// **********
// Import setting default bootstrap & custom variables
// **********
@import "../../settings/setting";

.sc-Container_Services {
  background-color: $bg-body;
  padding: 0 0 50px;
  @include media-breakpoint-up(md) {
    padding: 0 0 100px;
  }

  .sc-Img_Hover-Zoom {
    position: relative;
    overflow: hidden;
    cursor: pointer;
    @include media-breakpoint-down(sm) {
      margin-bottom: 15px;
    }
    &:hover {
      img {
        transform: scale(1.2);
      }
    }

    img {
      transition: transform .9s ease-in-out;
    }

    .sc-Title {
      position: absolute;
      bottom: 25%;
      left: 0;
      right: 0;
      text-align: center;
      font-size: 1rem;
      color: $white;
      @include media-breakpoint-up(lg) {
        font-size: 1.3rem;
      }
      @include media-breakpoint-down(sm) {
        font-size: 1.3rem;
      }
      &:after {
        content: '';
        position: absolute;
        bottom: -5px;
        left: 0;
        right: 0;
        margin: 0 auto;
        display: block;
        background-color: $white;
        width: 50px;
        height: 2px;
      }
    }

  }

}