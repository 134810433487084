// **********
// Import setting default bootstrap & custom variables
// **********
@import "../../settings/setting";

.sc-Container_Contact {
  padding: 0 0 80px;

  .sc-Text_Title {
    padding: 75px 0 100px;
    @include media-breakpoint-up(lg) {
      padding: 100px 0;
    }
  }

  h1 {
    position: relative;
    &:after {
      content: '';
      position: absolute;
      bottom: -30px;
      left: 0;
      right: 0;
      margin: 0 auto;
      display: block;
      background-color: $white;
      width: 50px;
      height: 2px;
    }
  }

  .sc-Container_Info-Contact {

    img {
      float: right;
      width: 45%;
      margin: -15px 30px 0 0;
    }

    .sc-Container_Phone {
      padding: 50px 0;
      margin-top: -10px;
      @include media-breakpoint-up(md) {
        text-align: center;
        padding: 0;
      }

      img {
        margin: -5px 30px 0 0;
      }

      .sc-Container_Number {
        float: left;
        @include media-breakpoint-down(sm) {
          margin-top: -5px;
        }
      }

      a {
        display: block;
      }

    }

    .sc-Container_Address {
      margin-top: -20px;
    }

    a {
      color: $white;
    }

    p {
      color: $white;
    }

  }

}